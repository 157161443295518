
import CustomButton from "@/components/CustomButton.vue";
import CustomInput from "@/components/CustomInput.vue";
import { defineComponent, PropType } from "vue";
interface Item {
  id: string;
  status: boolean;
  name: string;
  filename: string;
}
export default defineComponent({
  name: "ProgramTemplate",
  components: { CustomButton, CustomInput },
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
  },
  data() {
    return {
      newStatus: this.item.status,
      newName: this.item.name,
      newFilename: this.item.filename,
    };
  },
  methods: {
    saveButton() {
      this.$emit("save", {
        status: this.newStatus,
        name: this.newName,
        filename: this.newFilename,
        id: this.item.id,
      });
    },
  },
});
