
import CustomButton from "@/components/CustomButton.vue";
import CustomDropDown from "@/components/CustomDropDown.vue";
import InformationTemplate from "@/components/InformationTemplate.vue";
import ProgramTemplate from "./ProgramTemplate.vue";

import electronBridge from "@/utils/electronBridge";
import {
  addListeningProgram,
  getListeningProgram,
  restartListener,
} from "@/utils/programActivity";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ProgramActivity",
  components: {
    CustomButton,
    CustomDropDown,
    InformationTemplate,
    ProgramTemplate,
  },
  data() {
    return {
      programs: [] as any,
      listeningPrograms: [] as any,
      selectedID: null,
    };
  },
  async mounted() {
    if (!this.$isElectron) return;
    this.getData();
  },
  methods: {
    getData() {
      electronBridge
        ?.invoke("get_running_programs", getListeningProgram())
        .then((programs) => {
          this.programs = programs.map((p) => {
            return {
              name: p.name,
              note: p.filename,
              id: p.filename,
            };
          });
          this.programs.unshift({
            name: "Custom",
            note: "Add Your Own",
            id: "custom.exe",
          });
        });
      this.listeningPrograms = getListeningProgram();
      restartListener();
    },
    addProgram() {
      if (!this.selectedID) return;
      const program = this.programs.find((p) => p.id === this.selectedID);
      this.selectedID = null;
      addListeningProgram({
        name: program.name,
        filename: program.id,
        status: "Playing",
        id: Math.random().toString(),
      });
      this.getData();
    },
    deleteClicked(id: string) {
      let programs = getListeningProgram();
      programs = programs.filter((p) => id !== p.id);
      localStorage["programActivity"] = JSON.stringify(programs);
      this.getData();
    },
    saveClicked(id: string, updatedItem) {
      const programs = getListeningProgram();
      const index = programs.findIndex((p) => id === p.id);
      if (index === -1) return;
      programs[index] = updatedItem;
      localStorage["programActivity"] = JSON.stringify(programs);
      this.getData();
    },
  },
});
